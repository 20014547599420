import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../context';
import Header from '../header';
import './login.css';

export default function Login() {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('Введите код доступа');
  const { userState, ajax } = useContext(Context);

  useEffect(() => {
    if (password.length === 4) {
      ajax('checkauth', password).then((result) => {
        if (typeof result === 'object' && 'id' in result) {
          // авторизовались. Получили объект User
          localStorage.setItem('userId', result.id);
          userState('set', result.id);
        } else {
          setPassword((password) => '');
          setMessage((message) => 'Не верный код!');
        }
      }).catch((error) => {
        setPassword((password) => '');
        setMessage((message) => 'Нет соединения с сервером!');
      })
    }
  }, [password]);

  return (
    <div className="container" style={{ width: '85vw' }}>
      <Header
        html={<img src="images/logo.svg" alt="" />}
        style={{ height: '200px' }}
      />
      <div className="login-container__form">
        <p>{message}</p>
        <input
          className="form-input"
          value={password}
          // onChange={handleChange}
          onChange={(event) => setPassword(event.target.value)}
          autoсomplete="off"
          size={4}
          autoFocus
          type="number"
          id="code"
        />
      </div>
    </div>
  );
}
