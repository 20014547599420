import React, {useState, useContext} from 'react';
import {Context} from '../context';
import './calendar.css';
import DetailItem from './detailitem';
import GeneralItem from './generalitem';

const Item = (props) => {
  const [Detail, setDetail] = useState(null);
  const {ajax} = useContext(Context);

  const openDetail = (id) => {
    // console.log (id)
    if (!Detail) {
      ajax('getdetail', id).then((result) => setDetail(result));
    } else setDetail(null);
  };

  let deadLine = new Date(props.itemData.end);
  let itemClassname = 'item__container';
  if (props.itemData.status !== 'finished') {
    let deadlineClassname = '';
    if (deadLine < new Date()) deadlineClassname = 'lite-deadline';
    deadLine.setMinutes(deadLine.getMinutes() + Number(props.itemData.buffer));
    if (deadLine < new Date()) deadlineClassname = 'hard-deadline';
    itemClassname += ' '+deadlineClassname;
  }
  return (
    <div className={itemClassname}>
      <GeneralItem
        itemData={props.itemData}
        openDetail={openDetail}
        miniStyle={false}
      />
      {Detail && (
        <DetailItem
          dealData={Detail}
          taskData={props.itemData}
          setDetail={setDetail}
          changeStatus={props.changeStatus}
        />
      )}
    </div>
  );
};

export default Item;
