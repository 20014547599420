import React, { useEffect, useState } from "react";
import Calendar from "./calendar/calendar";
import { Context } from "./context";
import Login from "./login/login";

export default function App() {
  const [userId, setUserId] = useState(
    localStorage.getItem("userId") !== "null"
      ? localStorage.getItem("userId")
      : null
  );
  const [loader, setloader] = useState(false);
  const [hideFinished, setHideFinished] = useState(true);
  const [date, setDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [time, setTime] = useState(new Date());
  const [search, setSearch] = useState("");
  const [superSearch, setSuperSearch] = useState("");
  const [notFinished, setNotFinished] = useState({ deadline: 0, stuck: 0 });

  const getFormatDate = (serverFormat = false) => {
    let Day = ("0" + date.getDate()).slice(-2);
    let Month = ("0" + (date.getMonth() + 1)).slice(-2);
    let Year = date.getFullYear();
    return serverFormat
      ? Year + "-" + Month + "-" + Day
      : Day + "." + Month + "." + Year;
  };

  const dateState = (action = "get", value) => {
    switch (action) {
      case "set":
        setDate(value);
        break;

      case "dec":
        setDate(new Date(date.setDate(date.getDate() - 1)));
        break;

      case "inc":
        setDate(new Date(date.setDate(date.getDate() + 1)));
        break;

      case "get":
        return date;

      case "reload":
        setDate(new Date(date.setMinutes(0)));
        break;

      default:
        break;
    }
  };

  const searchState = (action = "get", value) => {
    if (action === "set") setSearch(value);
    else return search;
  };

  const itemsState = (action = "get", value) => {
    if (action === "set") {
      setItems(value);
    } else return items;
  };

  const userState = (action = "get", value) => {
    if (action === "set") setUserId(value);
    else return userId;
  };

  const superSearchState = (action = "get", value) => {
    if (action === "set") setSuperSearch(value);
    else return superSearch;
  };

  const loaderState = (action = "get", value) => {
    if (action === "set") setloader(value);
    else return loader;
  };

  const hideFinishedState = (action = "get", value) => {
    if (action === "set") setHideFinished(value);
    else return hideFinished;
  };

  const timeState = (action = "get", value) => {
    if (action === "set") setTime(value);
    else return time;
  };

  const ajax = (api, data = null) => {
    return new Promise((resolve, reject) => {
      loaderState("set", true);
      // console.log (api, data)
      let url = "https://api-garage.korobook.ru/api/" + api;
      // let url = 'http://127.0.0.1:8000/api/' + api;
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        method: "post",
        body: JSON.stringify({ data: data }),
      })
        .then((response) => response.json())
        .then((response) => {
          // console.log (response)
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        })
        .finally(() => loaderState("set", false));
    });
  };

  const loadTasks = () => {
    let sendData = { user_id: userId };
    if (superSearchState() !== "") {
      sendData.date = "all";
      sendData.deal = searchState();
    } else {
      sendData.date = getFormatDate(true);
    }
    ajax("getcalendar", sendData).then((result) => {
      itemsState("set", result.tasks);
      setNotFinished(result.notfinished);
    });
  };

//   setInterval(loadTasks, 1000*30);
  useEffect(() => {
    if (!userId) return;
    loadTasks();
  }, [date, userId, superSearch]);

  return (
    <Context.Provider
      value={{
        userState,
        loaderState,
        hideFinishedState,
        dateState,
        timeState,
        itemsState,
        getFormatDate,
        ajax,
        searchState,
        superSearchState,
        superSearch,
      }}
    >
      <div>{userId ? <Calendar notFinished={notFinished} /> : <Login />}</div>
    </Context.Provider>
  );
}
