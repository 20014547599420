import React, { useContext } from "react";
import { Context } from "../context";
import Item from "./item";
import Header from "../header";
import "./calendar.css";
import CalendarHeaderHtml from "./calendar-header";

export default function Calendar(props) {
  // console.log(props)
  const { itemsState, hideFinishedState, superSearch, searchState } =
    useContext(Context);

  let taskCount = 0;
  let lastDate = null;
  let renderDate = false;
  return (
    <div className="container" style={{ width: "100vw" }}>
      <Header html={CalendarHeaderHtml()} style={headerStyle} />
      <div style={{ height: "130px" }}></div>
      {(props.notFinished.deadline > 0 || props.notFinished.stuck > 0) &&
        superSearch === "" && (
          <div className="overtask">
            Ранее есть не закрытые задачи:{" "}
            <span style={{ backgroundColor: "#AAEB77" }}>
              {props.notFinished.deadline}
            </span>
            {" + "}
            <span style={{ backgroundColor: "#EBD877" }}>
              {props.notFinished.stuck}
            </span>{" "}
            шт
          </div>
        )}
      {itemsState().map((item, i) => {
        if (
          (item.status !== "finished" || !hideFinishedState()) &&
          (searchState() === "" ||
            (item.deal !== null && item.deal.indexOf(searchState()) !== -1) ||
            item.name.indexOf(searchState()) !== -1)
        ) {
          taskCount++;
          const startDate = item.start.substr(0, 10);
          if (startDate !== lastDate) {
            lastDate = startDate;
            renderDate = true;
          } else renderDate = false;
          //   console.log(lastDate, startDate);
          return (
            <div key={i}>
              {renderDate && superSearch !== "" && (
                <p className="calendar-list-date">{startDate}</p>
              )}
              <Item itemData={item} />
            </div>
          );
        }
      })}
      <p>Всего задач: {taskCount} шт</p>
      <div style={{ height: "60px" }}></div>
    </div>
  );
}

const headerStyle = {
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "20px",
};
