import React, {useContext, useEffect, useState} from "react";
import {Context} from "../context";
import GeneralItem from "./generalitem";

export default function DetailItem(props) {
    // console.log(props.dealData);
    const {ajax, dateState} = useContext(Context);
    const [masterMessage, setMasterMessage] = useState("");

    const ArrowUp = () => {
        return (
            <div style={{textAlign: "center", marginTop: "30px"}}>
                <button
                    className="arrow-up-button"
                    onClick={() => props.setDetail(null)}
                >
                    <img src="images/arrow-up.svg" alt=""/>
                </button>
            </div>
        );
    };

    const ActionButtons = ({taskData}) => {
        return (
            // typeof taskData.stuck === 'undefined' && taskData.status !== 'finished' && taskData.taskidbefore !== null
            <div className="action-buttons">
                <button
                    className={
                        typeof taskData.stuck !== "undefined" ||
                        taskData.status === "finished" ||
                        masterMessage === ""
                            ? "inactive-button"
                            : ""
                    }
                    style={{backgroundColor: "#ff5858"}}
                    onClick={() => {
                        ajax("taskaction", {
                            action: "mastermessage",
                            message: masterMessage,
                            taskId: taskData.id,
                        }).then((result) => {
                            if (result !== false) {
                                dateState("reload");
                                props.setDetail(null);
                            }
                        });
                    }}
                >
                    Сообщить о проблеме
                </button>
                <button
                    style={
                        taskData.status !== "finished" ? {backgroundColor: "#8fe28f"} : {}
                    }
                    onClick={() => {
                        ajax("taskaction", {
                            action: taskData.status === "finished" ? "wait" : "finished",
                            taskId: taskData.id,
                        }).then((result) => {
                            if (result !== false) {
                                dateState("reload");
                                props.setDetail(null);
                            }
                        });
                    }}
                >
                    {taskData.status !== "finished"
                        ? "Завершить задачу"
                        : "Восстановить задачу"}
                </button>
            </div>
        );
    };

    const ManagerBlock = () => {
        return (
            <div className="manager__block">
                {props.dealData.deal.params.manager} - менеджер
                {props.dealData.deal.params.managernote!=='' && <div className="cloud-message" style={{display: "flex"}}>
                {/*props.taskData.managernote===1 && <div className="cloud-message" style={{display: "flex"}*/}

                    <div
                        className="padding-box info-symbol info-symbol__manager-info"
                        style={{marginRight: "5px"}}>i
                    </div>
                    <div>{props.dealData.deal.params.managernote}</div>
                </div>}
            </div>
        );
    };

    const DetailBlock = () => {
        return (
            <div className="deal-detail-block">
                {Object.keys(props.dealData.deal.products).map((index, keyList) => {
                    return (
                        <ul className="cloud-message" key={keyList}>
                            <li className="product-index padding-box">{index}</li>
                            {Object.keys(props.dealData.deal.products[index]).map(
                                (param, keyList) => {
                                    let value = props.dealData.deal.products[index][param];
                                    if (["productname"].indexOf(param) === -1) param += ":";
                                    else {
                                        param = value;
                                        value = "";
                                    }
                                    return (
                                        <li key={keyList}>
                                            <span className="deal-param">{param}</span>
                                            {param === "Ссылка на макет:" ? (
                                                <a href={trueHref(value)} target="blank">
                                                    {value}
                                                </a>
                                            ) : (
                                                <span className="deal-param-value">{value}</span>
                                            )}
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    );
                })}
            </div>
        );
    };

    const trueHref = (href) => {
        if (!href.includes("https")) href = "https://" + href;
        return href;
    };

    const GeneralDetailBlock = () => {
        return (
            <div className="deal-detail-block">
                <ul className="cloud-message">
                    {Object.keys(props.dealData.deal.params).map((param, keyList) => {
                        let skip = false;
                        [
                            "addinfo",
                            "ПВЗ",
                            "Город",
                            "Получатель",
                            "Телефон",
                            "deal",
                            "managernote",
                            "manager",
                            "dealid",
                        ].forEach((el) => {
                            if (param === el) skip = true;
                        });
                        if (skip) return null;
                        let value = props.dealData.deal.params[param];
                        return (
                            <li key={keyList}>
                                <span className="deal-param">{param}</span>
                                <span className="deal-param-value">{value}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const Stuck = () => {
        // console.log (props.taskData)
        return (
            <div>
                <div style={{margin: "20px 0 0 5px", fontWeight: "bold"}}>
                    Задача приостановлена. Мастер {props.taskData.stuck.masterName}
                </div>
                <div className="cloud-message">
                    <GeneralItem
                        itemData={props.taskData.stuck}
                        openDetail={null}
                        miniStyle={true}
                    />
                </div>
                <br></br>
            </div>
        );
    };

    const NextTask = () => {
        return (
            <div>
                <div style={{margin: "20px 0 0 5px", fontWeight: "bold"}}>
                    Следующая задача - {props.dealData.nextTask.masterName.name}
                </div>
                <div className="cloud-message">
                    <GeneralItem
                        itemData={props.dealData.nextTask}
                        openDetail={null}
                        miniStyle={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="item__container__detail">
            <ArrowUp/>
            <ActionButtons taskData={props.taskData}/>
            {typeof props.taskData.stuck === "undefined" &&
            props.taskData.status !== "finished" && (
                <div>
                    <input
                        className="master-message"
                        type="text"
                        value={masterMessage}
                        onChange={(event) => setMasterMessage(event.target.value)}
                        placeholder="Опишите проблему и нажмите ПРИОСТАНОВИТЬ"
                    />
                </div>
            )}
            {typeof props.taskData.stuck !== "undefined" && <Stuck/>}
            {props.dealData.deal && <ManagerBlock/>}
            {props.dealData.deal && <DetailBlock/>}
            {props.dealData.deal && <GeneralDetailBlock/>}
            {props.dealData.nextTask && <NextTask/>}
            {/* <FeedBack /> */}
            <ArrowUp/>
        </div>
    );
}
