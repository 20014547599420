import React, { useContext, useState } from "react";
import { Context } from "../context";

export default function CalendarHeaderHtml() {
  const [showSearch, setShowSearch] = useState(
    localStorage.getItem("showSearch") === "false" ? false : true
  );

  const {
    userState,
    hideFinishedState,
    dateState,
    timeState,
    getFormatDate,
    searchState,
    superSearchState,
    superSearch,
  } = useContext(Context);

  const superSearchButton = () => {
    superSearchState("set", searchState());
  };

  const resetSearchButton = () => {
    searchState("set", "");
    superSearchState("set", "");
  };

  const HideFinishedTaskButton = () => {
    if (hideFinishedState()) {
      return (
        <button
          className="finished-hide-button"
          onClick={() => {
            hideFinishedState("set", false);
          }}
        >
          Показать выполненные задачи
        </button>
      );
    } else {
      return (
        <button
          className="finished-hide-button"
          onClick={() => {
            hideFinishedState("set", true);
          }}
        >
          Скрыть выполненные задачи
        </button>
      );
    }
  };

  const SwitchSearchTimeButton = () => {
    return (
      <button
        className="finished-hide-button"
        style={{ marginTop: "20px" }}
        onClick={() => {
          localStorage.setItem("showSearch", !showSearch);
          setShowSearch(!showSearch);
        }}
      >
        {showSearch ? "Показать часы" : "Показать поиск"}
      </button>
    );
  };

  const DateBlock = () => {
    return (
      <div
        onClick={() => {
          dateState("set", new Date());
        }}
      >
        <div style={{ fontSize: "22px", fontWeight: 300 }}>
          {getFormatDate()}
        </div>
        <div style={{ fontSize: "22px", fontWeight: 300, color: "#525252" }}>
          {
            [
              "Воскресение",
              "Понедельник",
              "Вторник",
              "Среда",
              "Черверг",
              "Пятница",
              "Суббота",
            ][dateState().getDay()]
          }
        </div>
      </div>
    );
  };

  const ChangeDateButton = (props) => {
    return (
      <button
        className="big-transparent-button"
        onClick={() => dateState(props.type)}
        style={
          props.type === "inc"
            ? {
                marginLeft: "20px",
                backgroundImage: "url(images/round-button-left.svg)",
                transform: "rotate(180deg)",
              }
            : {
                marginRight: "20px",
                backgroundImage: "url(images/round-button-left.svg)",
              }
        }
      ></button>
    );
  };

  const LogOutButton = () => {
    return (
      <button
        className="logout-button"
        onClick={() => {
          localStorage.removeItem("userId");
          userState("set", null);
        }}
      >
        Выйти из аккаунта
      </button>
    );
  };

  const Time = () => {
    setInterval(() => timeState("set", new Date()), 60 * 1000);

    return (
      <div style={{ fontSize: "18px", marginTop: "4px" }}>
        {("0" + timeState().getHours()).slice(-2) +
          ":" +
          ("0" + timeState().getMinutes()).slice(-2)}
      </div>
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <HideFinishedTaskButton />
      <SwitchSearchTimeButton />
      <ChangeDateButton type={"dec"} />
      <div style={{ width: "140px" }} className="cursor-pointer">
        {superSearch === "" && <DateBlock />}

        {showSearch ? (
          <div>
            <div className="head-search">
              <input
                type="text"
                placeholder="Поиск"
                value={searchState()}
                onChange={(event) => searchState("set", event.target.value)}
              ></input>
              <button className="clear-search" onClick={resetSearchButton}>
                x
              </button>
            </div>
            {searchState() !== ""  && (
              <div>
                <button className="super-search-button" onClick={superSearchButton}>найти</button>
              </div>
            )}
          </div>
        ) : (
          <Time />
        )}
      </div>
      <ChangeDateButton direction={1} type={"inc"} />
      <LogOutButton />
    </div>
  );
}
