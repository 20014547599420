export default function GeneralItem(props) {

  return (
    <div
      className="item__container__general cursor-pointer"
      onClick={() => props.openDetail(props.itemData.id)}
    >
      <div>
        <div className="item__title">
          <div>
            <div
              className={'padding-box task-status-' + props.itemData.status}
              style={{
                lineHeight: '1.5',
                marginRight: '5px',
              }}
            >
              {props.itemData.deal}
            </div>
            {props.itemData.managernote ===1 && (
              <div className="padding-box info-symbol info-symbol__manager-info">
                i
              </div>
            )}
            {typeof props.itemData.stuck !== 'undefined' && (
              <div className="padding-box info-symbol info-symbol__stuck">
                !
              </div>
            )}
          </div>
          <div style={{display: 'flex'}}>
            <div
              className="padding-box"
              style={{
                border: '1px solid grey',
                marginRight: '6px',
              }}
            >
              {props.itemData.start.substr(11, 5)}
            </div>
            <div
              className="padding-box"
              style={{
                border: '1px solid grey',
              }}
            >
              {props.itemData.time} мин
            </div>
          </div>
        </div>
        <div className="item__data">
          <div style={{fontWeight: 'bold'}}>{props.itemData.name}</div>
          {!props.miniStyle && (
            <div>
              <div>{props.itemData.generalinfo}</div>
              <div>{props.itemData.info}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
