import React, {useContext} from 'react'
import {Context} from './context';
import './header.css'

export default function Header(props) {
  const {userState, loaderState, hideFinishedState, Ajax} = useContext(Context);
    return (
        <div
          className={!loaderState()?'header':'header header__animation'}
          style={props.style}
        >
          {props.html}
        </div>
    )
}
